export const LIFESTYLE_CONFIG = {
    "Tall Family Members": {
        "frontLegRoom":{
            text: "Front Leg Room (mm)",
            type: "value"
        },
        "frontHeadRoom":{
            text: "Front Head Room (mm)",
            type: "value"
        }
    },
    "Large Dog": {
        "interiorRearCargoVolume":{
            text: "Interior Rear Cargo Vol (L)",
            type: "value"
        },
        "cargoCapacityAllSeatsInPlace": {
            text: "Cargo Capacity All Seats In Place (L)",
            type: "value"
        },
        "maxCargoCapacity": {
            text: "Max Cargo Capacity (L)",
            type: "value"
        },
        "interiorMaximumRearCargoVolume":{
            text: "Interior Maximum Rear Cargo Vol (L)",
            type: "value"
        },
    },
    "Multiple Main Drivers": {
        "seatMemory":{
            text: "Seat Memory",
            type: "boolean"
        },
        "powerSeats":{
            text: "Power Seats",
            type: "boolean"
        }
    },
    "1-2 Kids": {
        "rearLegroom":{
            text:"Rear Legroom (mm)",
            type: "value"
        }, 
        "heatedRearSeats":{
            text: "Heated Rear Seats",
            type: "boolean"
        }
    }, 
    "3-4 Kids": {
        "seatingCapacity": {
            text: "Seating Capacity",
            type: "value",
        },
        "thirdRowLegroom":{
            text: "Third Row Leg Room (mm)",
            type: "value"
        }
    },
    "5+ Kids": {
        "seatingCapacity": {
            text: "Seating Capacity",
            type: "value",
        },
        "thirdRowLegroom":{
            text: "Third Row Leg Room (mm)",
            type: "value"
        }
    },
    "Older Family Members": {
        "groundClearance":{
            text: "Ground Clearance (mm)",
            type: "value"
        }
    },
    "New Driver": {
        "blindSpotSensor":{
            text: "Blind Spot Sensor",
            type: "boolean"
        },
        "brakeAssist":{
            text: "Brake Assist",
            type: "boolean"
        },
        "rearCollisionAlert":{
            text: "Rear Collision Alert",
            type: "boolean"
        },
        "rearParkingSensors":{
            text: "Rear Parking Sensors",
            type: "boolean"
        },
        "frontParkingSensors":{
            text: "Front Parking Sensors",
            type: "boolean"
        },
        "wirelessPhoneConnectivity":{
            text:"Bluetooth",
            type: "boolean"
        }
    },
    "Car Seat(s)": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        },
        "childSafetyLock":{
            text:"Child Safety Lock",
            type:"boolean"
        }
    },
    "Golfing": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        }
    },
    "Skiing/Snowboarding": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        },
        "roofRack":{
            text:"Roof Rails",
            type:"boolean"
        }
    },
    "Biking": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        },
        "roofRack":{
            text:"Roof Rails",
            type:"boolean"
        }
    },
    "Camping": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        },
        "powerOutlet12VDC":{
            text:"12V DC Power Outlets",
            type:"value"
        },
        "powerOutlet120VAC":{
            text:"120V AC Power Outlets",
            type:"value"
        }
    },
    "Canoe/Kayak": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        }
    },
    "Sports Practices": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        },
        "cargoCapacityAllSeatsInPlace": {
            text: "Cargo Capacity All Seats In Place (L)",
            type: "value"
        },
    },
    "Trailer": {
        "towingCapacity":{
            text:"Towing Capacity (lb)",
            type:"value"
        }
    },
    "Boat": {
        "towingCapacity":{
            text:"Towing Capacity (lb)",
            type:"value"
        }
    },
    "Camper": {
        "towingCapacity":{
            text:"Towing Capacity (lb)",
            type:"value"
        }
    },
    "Off Roading": {
        "driveTrain": {
            text: "Drive Train",
            type: "value",
            location: "not in maefeatures"
        },
        "groundClearance":{
            text: "Ground Clearance (mm)",
            type: "value"
        },
        "heatedSteeringWheel": {
            text:"Heated Steering Wheel",
            type:"boolean"
        },
        "heatedSeats": {
            text:"Heated Front Seats",
            type:"boolean"
        }
    },
    "Sporty Driving": {
        "torque": {
            text: "Torque",
            type: "value"
        },
        "horsepower": {
            text: "Horsepower",
            type: "value"
        }
    },
    "Lots of City/Traffic": {
        "stopAndGo": {
            text: "Stop and Go",
            type: "boolean"
        }
    },
    "Lots of Highway Driving": {
        "adaptiveCruiseControl": {
            text: "Adaptive Cruise Control",
            type: "boolean"
        },
        "laneDepartureWarning": {
            text: "Lane Departure Warning",
            type: "boolean"
        },
        "autoHighBeamHeadlights": {
            text: "Auto High Beem Headlights",
            type: "boolean"
        },
        "overallLength": {
            text: "Overall Length (mm)",
            type: "value"
        },
        "exteriorLength": {
            text: "Exterior Length (mm)",
            type: "value"
        }
    },
    "Snow": {
        "driveTrain": {
            text: "Drive Train",
            type: "value",
            location: "not in maefeatures"
        }
    }

}