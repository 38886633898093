import { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { DEALERSHIP_CONFIG } from "../config/dealershipConfig";

const DealershipContext = createContext("dealershipName");

export const DealershipProvider = ({ children }) => {
  const [dealershipId, setDealershipId] = useState("camclarkford");
  const [salesPersonInfo, setSalesPersonInfo] = useState(["",""])
  const [dealershipName, setDealershipName] = useState(DEALERSHIP_CONFIG["camclarkford"].name);
  const [dealershipImgUrl, setDealershipImgUrl] = useState(DEALERSHIP_CONFIG["camclarkford"].imgUrl);
  const [linkMatchesToVDP, setLinkMatchesToVdp] = useState(DEALERSHIP_CONFIG["camclarkford"].linkMatchesToVDP);
  const [defaultInventoryImage, setDefaultInventoryImage] = useState(DEALERSHIP_CONFIG["camclarkford"].defaultInventoryImage);
  const salesPeople = DEALERSHIP_CONFIG["camclarkford"].salesPeople
  const location = useLocation();
  const navigate = useNavigate();
  const [userSource, setUserSource] = useState("Walk-In");

  useEffect(() => {
    const urlDealershipId = location.pathname.split('/')[1]; 
    if (urlDealershipId in DEALERSHIP_CONFIG) {
      setDealershipId(urlDealershipId);
      setDealershipName(DEALERSHIP_CONFIG[urlDealershipId].name)
      setDealershipImgUrl(DEALERSHIP_CONFIG[urlDealershipId].imgUrl)
      setLinkMatchesToVdp(DEALERSHIP_CONFIG[urlDealershipId].linkMatchesToVDP)
      setDefaultInventoryImage(DEALERSHIP_CONFIG[urlDealershipId].defaultInventoryImage)
      console.log("DEALERSHIP CONTEXT",urlDealershipId, DEALERSHIP_CONFIG[urlDealershipId].name, DEALERSHIP_CONFIG[urlDealershipId].imgUrl)
    } else {
      navigate('/')
    }

    const localStorageUserSource = localStorage.getItem("dealership-user-source");
    const localStorageSalesPersonInfo = localStorage.getItem("dealership-salesperson-id")

    if (localStorageUserSource === null) {
      const params = new URLSearchParams(location.search);
      const sourceParam = params.get("source");

      if (sourceParam) {
        setUserSource(sourceParam);
        localStorage.setItem("dealership-user-source", sourceParam);
      }
    } else {
      setUserSource(localStorageUserSource);
    }

    const params = new URLSearchParams(location.search);
    const salespersonParam = params.get("sp");

    if (salespersonParam) {
      const salesPersonInfoTemp = salesPeople[salespersonParam]
      if (salesPersonInfoTemp){
        setSalesPersonInfo(salesPersonInfoTemp);
        localStorage.setItem("dealership-salesperson-id", [salesPersonInfoTemp.name, salesPersonInfoTemp.email]);
      }
    } else if (localStorageSalesPersonInfo !== null) {
      setSalesPersonInfo(localStorageSalesPersonInfo.split(","));
    } 
  }, []);

  const clearDealershipUserSource = () => {
    localStorage.removeItem("dealership-user-source")
  }

  const clearSalesPersonInfo = () => {
    localStorage.removeItem("dealership-salesperson-id")
  }

  const changeSalesPersonInfo = (newValue) => {
    setSalesPersonInfo(newValue)
    localStorage.setItem("dealership-salesperson-id", [newValue.name,newValue.email]);
    console.log(salesPersonInfo)
  }

  return (
    <DealershipContext.Provider
      value={{
        dealershipName,
        setDealershipName,
        dealershipImgUrl,
        dealershipId,
        linkMatchesToVDP,
        defaultInventoryImage,
        clearDealershipUserSource,
        clearSalesPersonInfo,
        userSource,
        salesPersonInfo,
        changeSalesPersonInfo
      }}
    >
      {children}
    </DealershipContext.Provider>
  );
};

export const useDealershipContext = () => {
  return useContext(DealershipContext);
};
