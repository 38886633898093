export const createDealershipUser = async (
  quizSelections,
  personalInformation,
  dealershipId,
  userSource,
  salesPersonId,
  salesPersonEmail
) => {
  console.log("Request Sent");
  try {
    const response = await fetch("/api/dealershipuser/createdealershipuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        selected: quizSelections,
        personalInformation,
        dealershipId,
        userSource,
        salesPersonId,
        salesPersonEmail
      }),
    });

    if (response.ok) {
      return await response.json();
    }
    const errorData = await response.json();
    console.log("Failed:", errorData.error);
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const getDealershipUserInfo = async (id,email) => {
  try {
    const response = await fetch("/api/dealershipuser/getdealershipuserinfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: id,
        openedThroughEmail : Number(email),
      }),
    });

    if (response.ok) {
      return await response.json();
    }
    const errorData = await response.json();
    console.log("Failed:", errorData.error);
    return null;
  } catch (error) {
    console.log(error);
  }
};
