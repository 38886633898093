import "./LikedList.scss";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import EllipsePercentMatch from "../EllipsePercentMatch/EllipsePercentMatch";
import { formatPrice } from "../../utils/formatUtils";
import { getDealershipUserInfo } from "../../services/dealership-user";
import RestartButtonContainer from "../RestartButton/RestartButton";
import discountedGreenIcon from "../../assets/icons/discount-green.png"
import discountedRedIcon from "../../assets/icons/discount-red.png"
import { useDealershipContext } from "../../contexts/dealershipContext";
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import ButtonGradient from "../ButtonGradient/ButtonGradient";
import {ReactComponent as MaeCoreLogoYellow} from '../../assets/logos/Mae-Minimized-Logo-Yellow.svg';
import NoMatches from "../NoMatches/NoMatches";
import ComparisonChartStatic from "../ComparisonChartStatic/ComparisonChartStatic";
import {ReactComponent as Star} from '../../assets/icons/star.svg';
import { AGED_INVENTORY } from "../../data/agedInventory";
import UserProfile from "../UserProfile/UserProfile";
import { LIFESTYLE_INDEX } from "../../constants/quizQuestionsIndex";
import { ReactComponent as CompareSelected } from "../../assets/icons/compare-selected.svg";
import {ReactComponent as ChevronUp} from "../../assets/icons/chevron-up.svg";
import {ReactComponent as ChevronDown} from "../../assets/icons/chevron-down.svg";

export function MatchSection({ vehicle }) {
  return (
    <div className="liked-card-match">
      {" "}
      <div className="liked-card-match__circle">
        <div className="liked-card-match-coloured-circle">
        <EllipsePercentMatch percentage={vehicle.compatibilityPercentage} />
        </div>
        <div className="liked-card-match-stamp">
          <p className="liked-card-match-stamp__number">
            {vehicle.compatibilityPercentage}
            <span className="liked-card-match-stamp__percent">%</span>
          </p>
          <p className="liked-card-match-stamp__text">match</p>
        </div>
      </div>
      <div className="match__text">
        <div className="match__text--row">
          <p className="text-bold">8/8</p>
          <p className="text-normal">requirements</p>
        </div>
        <div className="match__text--row">
          <p className="text-bold">
            {" "}
            {vehicle.matchedFeatures.length}/{vehicle.totalFeatures.length}
          </p>
          <p className="text-normal">features</p>
        </div>
      </div>
    </div>
  );
}

function LikedList() {

  const navigate = useNavigate();
  const {dealershipName,dealershipId,linkMatchesToVDP,dealershipImgUrl,defaultInventoryImage} = useDealershipContext();
  const [carMatches, setCarMatches] = useState([]);
  const [maeCarMatches,setMaeCarMatches] = useState([]);
  const [carsToCompareList, setCarsToCompareList] = useState([]);
  const [finalCarCompareList,setFinalCarCompareList] = useState([]);
  const [zeroMatchesCase, setZeroMatchesCase] = useState(false);
  const [user, setUser] = useState({});
  const targetDivRef = useRef(null);
  const cardContainerRef = useRef(null);
  const [showAllCars, setShowAllCars] = useState(false);
  const [numCarsToShow, setNumCarsToShow] = useState(4);

  const [openedThroughEmail,setOpenedThroughEmail] = useState(null);


  useEffect(() => {
    if(showAllCars) {
      setNumCarsToShow(carMatches.length);
    } else if(window.innerWidth < 600) {
      setNumCarsToShow(1);
    } else if(window.innerWidth < 850) {
      setNumCarsToShow(2);
    } else if(window.innerWidth < 1100) {
      setNumCarsToShow(3);
    } else if(window.innerWidth < 1250) {
        setNumCarsToShow(4);
    } else {
      setNumCarsToShow(5);
    }
  },[showAllCars, window.innerWidth])

  const lockScroll = () => {
    const scrollPosition = window.scrollY;
    window.scrollTo({ top: scrollPosition });
  };


  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setOpenedThroughEmail(params.get("email"));
  },[])

  useEffect(() => {
    const fetchUserInfo = async (id,email) => {
      const res = await getDealershipUserInfo(id,email);
      console.log(res, "res");

      if (res){
        if(res.matches === 0) {
          console.log("setting 0 match case true")
          setZeroMatchesCase(true);
        } else {
          const inventoryMatchesWithTags = addmaeInsights(res.inventoryMatches)
          console.log(inventoryMatchesWithTags)
          setCarMatches(inventoryMatchesWithTags);
          const maxThreeSelect = [];
          for(let i =0; i<inventoryMatchesWithTags.length; i++){
             if( i > 2) {
              break;
             }
             maxThreeSelect.push(i);
          }
          setCarsToCompareList(maxThreeSelect);
          setMaeCarMatches(res.maeTrimsMatches);
        }
        if(res.user) {
          setUser(res.user)
        }
      } else {
        navigate('/404')
      }
     
    };

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    console.log(params, "params", params.get("id"), "id");
    if (carMatches.length <= 0) {
      fetchUserInfo(params.get("id"),params.get("email"));
    } 
      
  }, []);

  const addmaeInsights = (inventoryMatches) => {
    const sortedCarsByPrice = inventoryMatches.sort((a, b) => a.baseMSRP - b.baseMSRP);

    const cheapestAWDCarId = sortedCarsByPrice.find(car => car.driveTrain == "All Wheel Drive" || car.driveTrain == "4x4")?.id;
    // const cheapestFWDCarId = sortedCarsByPrice.find(car => car.driveTrain == "Front Wheel Drive")?.id;
    const cheapestUsedAWDCarId = sortedCarsByPrice.find(car => (car.driveTrain == "All Wheel Drive" || car.driveTrain == "4x4") && car.newOrUsed == "Used")?.id;
    const cheapestNewAWDCarId = sortedCarsByPrice.find(car => (car.driveTrain == "All Wheel Drive" || car.driveTrain == "4x4") && car.newOrUsed == "New")?.id;
    // const cheapestUsedFWDCarId = sortedCarsByPrice.find(car => car.driveTrain == "Front Wheel Drive" && car.newOrUsed == "Used")?.id;
    // const cheapestNewFWDCarId = sortedCarsByPrice.find(car => car.driveTrain == "Front Wheel Drive" && car.newOrUsed == "New")?.id;
    const cheapestHybridCarId = sortedCarsByPrice.find(car => car.engine == "Hybrid")?.id;
    const cheapestUsedHybridCarId = sortedCarsByPrice.find(car => car.engine == "Hybrid" && car.newOrUsed == "Used")?.id;
    const cheapestNewHybridCarId = sortedCarsByPrice.find(car => car.engine == "Hybrid" && car.newOrUsed == "New")?.id;
    const cheapestPHEVCarId = sortedCarsByPrice.find(car => car.engine == "PHEV")?.id;
    const cheapestUsedPHEVCarId = sortedCarsByPrice.find(car => car.engine == "PHEV" && car.newOrUsed == "Used")?.id;
    const cheapestNewPHEVCarId = sortedCarsByPrice.find(car => car.engine == "PHEV" && car.newOrUsed == "New")?.id;
    const cheapestElectricCarId = sortedCarsByPrice.find(car => car.engine == "Electric")?.id;
    const cheapestUsedElectricCarId = sortedCarsByPrice.find(car => car.engine == "Electric" && car.newOrUsed == "Used")?.id;
    const cheapestNewElectricCarId = sortedCarsByPrice.find(car => car.engine == "Electric" && car.newOrUsed == "New")?.id;
    const cheapestLargeSUVCarId = sortedCarsByPrice.find(car => car.maeSegment == "Medium/Large SUV")?.id;
    const cheapestSmallSUVCarId = sortedCarsByPrice.find(car => car.engine == "Small/Medium SUV")?.id;

    inventoryMatches.forEach(car => {
      car.maeInsights = []

      car.driveTrainShort = car.driveTrain
      if (car.driveTrain === "All Wheel Drive") {
        car.driveTrainShort = "AWD"
      } if (car.driveTrain === "Rear Wheel Drive") {
        car.driveTrainShort = "RWD"
      } if (car.driveTrain === "Front Wheel Drive") {
        car.driveTrainShort = "FWD"
      } 

      if (AGED_INVENTORY.includes(car.stockNumber)) {
        car.maeInsights.push("Manager's Special")
        car.agedInventory = true
      }

      if (cheapestUsedAWDCarId && cheapestNewAWDCarId) {
        if (car.id === cheapestUsedAWDCarId) {
          car.maeInsights.push(`Cheapest Used ${car.driveTrainShort}`) 
        }
        if (car.id === cheapestNewAWDCarId) {
          car.maeInsights.push(`Cheapest New ${car.driveTrainShort}`) 
        }
      } else {
        if (car.id === cheapestAWDCarId) {
          car.maeInsights.push(`Cheapest ${car.driveTrainShort}`) 
        }
      }

      // if (cheapestUsedFWDCarId && cheapestNewFWDCarId) {
      //   if (car.id === cheapestUsedFWDCarId) {
      //     car.maeInsights.push("Cheapest Used FWD"; 
      //   }
      //   if (car.id === cheapestNewFWDCarId) {
      //     car.maeInsights.push("Cheapest New FWD"; 
      //   }
      // } else {
      //   if (car.id === cheapestFWDCarId) {
      //     car.maeInsights.push("Cheapest FWD"; 
      //   }
      // }

      if (cheapestUsedElectricCarId && cheapestNewElectricCarId) {
        if (car.id === cheapestUsedElectricCarId) {
          car.maeInsights.push("Cheapest Used Electric"); 
        }
        if (car.id === cheapestNewElectricCarId) {
          car.maeInsights.push("Cheapest New Electric"); 
        }
      } else {
        if (car.id === cheapestElectricCarId) {
          car.maeInsights.push("Cheapest Electric"); 
        }
      }

      if (cheapestUsedHybridCarId && cheapestNewHybridCarId) {
        if (car.id === cheapestUsedHybridCarId) {
          car.maeInsights.push("Cheapest Used Hybrid"); 
        }
        if (car.id === cheapestNewHybridCarId) {
          car.maeInsights.push("Cheapest New Hybrid"); 
        }
      } else {
        if (car.id === cheapestHybridCarId) {
          car.maeInsights.push("Cheapest Hybrid"); 
        }
      }

      if (cheapestUsedPHEVCarId && cheapestNewPHEVCarId) {
        if (car.id === cheapestUsedPHEVCarId) {
          car.maeInsights.push("Cheapest Used PHEV"); 
        }
        if (car.id === cheapestNewPHEVCarId) {
          car.maeInsights.push("Cheapest New PHEV"); 
        }
      } else {
        if (car.id === cheapestPHEVCarId) {
          car.maeInsights.push("Cheapest PHEV"); 
        }
      }
      

      if (cheapestLargeSUVCarId && cheapestSmallSUVCarId) {
        if (car.id === cheapestLargeSUVCarId) {
          car.maeInsights.push("Cheapest Large SUV"); 
        }
      }
    });

    return inventoryMatches
  }

  if(carMatches.length === 0 && !zeroMatchesCase) {
    return <LoadingScreen />
  } 

  // if(zeroMatchesCase) {
  //   return <NoMatches/>
  // }

  

  const generateUserProfile = (event) => {
    event.preventDefault();
    const printContent = document.getElementById('print');
    const secondprintContent = document.getElementById('second-print');
    const noPrintHeader = document.getElementById('no-print-header');
    const noPrintHeaderTwo = document.getElementById('no-print-header-two');
    const noPrintList = document.getElementById('no-print-list');
    const noPrintListTwo = document.getElementById('no-print-list-two');
    const noPrintNoMatches = document.getElementById('no-print-nomatches')

    if(printContent){
      printContent.style.display = 'block';
    }
    
    if(secondprintContent){
      secondprintContent.style.display = 'none';
    }

    if(noPrintHeader) {
      noPrintHeader.style.display = 'none';
    }

    if(noPrintHeaderTwo) {
      noPrintHeaderTwo.style.display = 'none';
    }

    if (noPrintList) {
      noPrintList.style.display = 'none';
    }

    if (noPrintListTwo) {
      noPrintListTwo.style.display = 'none';
    }

    if(noPrintNoMatches) {
      noPrintNoMatches.style.display = 'none';
    }
    
  };

  const generateChart = (event) => {
    event.preventDefault();
    const printContent = document.getElementById('print');
    const secondprintContent = document.getElementById('second-print');
    const noPrintHeader = document.getElementById('no-print-header');
    const noPrintHeaderTwo = document.getElementById('no-print-header-two');
    const noPrintList = document.getElementById('no-print-list');
    const noPrintListTwo = document.getElementById('no-print-list-two');
    const noPrintNoMatches = document.getElementById('no-print-nomatches')

    if(printContent){
      printContent.style.display = 'none';
    }
    
    if(secondprintContent){
      secondprintContent.style.display = 'block';
    }

    if(noPrintHeader) {
      noPrintHeader.style.display = 'none';
    }

    if(noPrintHeaderTwo) {
      noPrintHeaderTwo.style.display = 'none';
    }

    if (noPrintList) {
      noPrintList.style.display = 'none';
    }

    if (noPrintListTwo) {
      noPrintListTwo.style.display = 'none';
    }

    if(noPrintNoMatches) {
      noPrintNoMatches.style.display = 'none';
    }
    
  };

  const backToMatches = (event) => {
    event.preventDefault();
    const printContent = document.getElementById('print');
    const secondprintContent = document.getElementById('second-print');
    const noPrintHeader = document.getElementById('no-print-header');
    const noPrintHeaderTwo = document.getElementById('no-print-header-two');
    const noPrintList = document.getElementById('no-print-list');
    const noPrintListTwo = document.getElementById('no-print-list-two');
    const noPrintNoMatches = document.getElementById('no-print-nomatches')

    if(printContent){
      printContent.style.display = 'none';
    }
    
    if(secondprintContent){
      secondprintContent.style.display = 'none';
    }
    
    if(noPrintHeader) {
      noPrintHeader.style.display = 'block';
    }

    if(noPrintHeaderTwo) {
      noPrintHeaderTwo.style.display = 'block';
    }

    if (noPrintList) {
      noPrintList.style.display = 'block';
    }

    if (noPrintListTwo) {
      noPrintListTwo.style.display = 'block';
    }

    if(noPrintNoMatches) {
      noPrintNoMatches.style.display = 'block';
    }
    
  };

  const scrollToTargetDiv = () => {
    //setFinalCarCompareList(carsToCompareList);
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const expandFunctionality = () => {
    setShowAllCars(!showAllCars);
    if(showAllCars) { scrollToTargetDiv()}
    if(!showAllCars) {lockScroll()}
  }


  const selectCarForCompare = (e,index) => {                                                                                                                
    const currCarsToCompareList = [...carsToCompareList];
    const carIndexPos = currCarsToCompareList.indexOf(index);
    if(carIndexPos === -1 && carsToCompareList.length > 3 ) {
      return;
    }
    if(carIndexPos === -1 && carsToCompareList.length === 3) {
      currCarsToCompareList.splice(0,1);
      currCarsToCompareList.push(index);
    } else if(carIndexPos === -1) {
      currCarsToCompareList.push(index);
    } else {
      currCarsToCompareList.splice(carIndexPos,1)
    }
    setCarsToCompareList(currCarsToCompareList);
    console.log(currCarsToCompareList,"currCars");
  }


  return (
    <div className="gallery" ref={targetDivRef}>
    <div className="gallery__hero" id="no-print-header">
        <div className="gallery__hero--main">
        <img className="product_info__dealership_logo" src={dealershipImgUrl} alt="" />
        <RestartButtonContainer dealershipId={dealershipId}/>
        </div>
        {zeroMatchesCase && openedThroughEmail === "0"? 
        <div style={{display:"flex",justifyContent:"flex-end"}}>
        <div style={{width:"15rem", marginTop:"1rem"}}>
          <ButtonGradient text="Print Profile" onClick={generateUserProfile}/> 
          </div> 
        </div>: null}

        {zeroMatchesCase? null :
        <div className="matches_page_heading">
          <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
          <h1 className ="matches_page_heading--text">{user.firstName}'s Matches</h1>
          <div className=".text" style={{fontWeight : "400",width:"auto", marginLeft:"0.5rem"}}>Powered By</div>
          <div className=".logo" style={{marginLeft:"0.5rem"}}>
              <MaeCoreLogoYellow />
          </div>
          </div>
          {openedThroughEmail === "1"? null : 
          <div style={{display:"flex", justifyContent:"space-evenly"}}> 
          <ButtonGradient text="Print Profile" onClick={generateUserProfile}/> 
          <span style={{width:"1rem"}}/> 
          <ButtonGradient text="Print Chart" onClick={generateChart}/>
          </div>}
        </div> }

        {zeroMatchesCase? null :
        <p>Your profile has been saved! Our Concierge has personalized a list of matches in {dealershipName}’s 
        inventory that best suits your profile - to save you time and hassle. Your Matches are now live and updated daily. 
        Click the link in your inbox any time to come back and see updated and available matches.</p> }

        {/* {zeroMatchesCase ? null :
          <div style={{width : "20%", marginInlineStart : "auto" , marginBlockStart : "1rem", display:"flex", justifyContent:"flex-end"}}> 
          <ButtonGradient text="Compare" onClick={scrollToTargetDiv}/> 
          </div> */}
        
    </div>
    
    {zeroMatchesCase ? <div id="no-print-nomatches"> <NoMatches/>  </div>:  
    <div ref={cardContainerRef} 
       style={{height: cardContainerRef, transition: 'height 0.5s ease'}} className="liked-list" id="no-print-list">
      <div className={`liked-list__wrapper`}>
        {carMatches.slice(0,numCarsToShow).map((vehicle,index) => {
          return (
            <div className={`car-card`}
            onClick={(e) => selectCarForCompare(e,index)}>
            
            <div className={`liked-card`} >
                <div className="liked-card__thumbnail">
                  { 
                  <div className="empty-selection">
                    {carsToCompareList.includes(index) ? <CompareSelected /> : null}
                  </div>}
                  <img
                    className="liked-card__thumbnail--img"
                    src={defaultInventoryImage? defaultInventoryImage:vehicle.imageLink}
                    alt={vehicle.make + vehicle.model}
                  />
                </div>
              <div className="liked-card__details">
                <div style={{display:"flex"}}>
                  <div className={`liked-card__details--${vehicle.newOrUsed}`}>
                    {`${vehicle.newOrUsed}`}
                  </div>
                  <div className={`liked-card__details--Other`}>
                    {`${vehicle.engine}`}
                  </div>
                  <div className={`liked-card__details--Other`}>
                    {`${vehicle.driveTrainShort}`}
                  </div>
                </div>
                {/* <div className={`liked-card__details--Other`}>
                    {`${vehicle.maeSegment}`}
                  </div> */}
                <div className="liked-card__details--title">
                  {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                </div>
                <div style={{fontSize:"0.9rem"}}>{`${vehicle.trim}`}</div>
                <div className="liked-card__details--price">
                  <div style={{display:"flex",alignItems:"center"}}>
                    ${formatPrice(vehicle.baseMSRP)}
                    {vehicle.discountedFlag && !vehicle.agedInventory===true?<img className="liked-card__details--discount" alt="" src={discountedGreenIcon}></img>:null}
                    {vehicle.agedInventory?<img className="liked-card__details--discount" alt="" src={discountedRedIcon} />:null}
                  </div>{" "}
                </div>
              </div>
              <MatchSection vehicle={vehicle} />

              <Link 
                  className="liked-card__details--link"
                  to={linkMatchesToVDP ? vehicle.internalLink: null} 
                  key={index} target={linkMatchesToVDP ? "_blank": "_self"}
                  onClick={(e) => {
                  if (!linkMatchesToVDP) {
                      e.preventDefault();
                    }
                }}>Click to View Vehicle</Link>

              {vehicle.maeInsights.map((featureTag, index) => {
                return featureTag === "Manager's Special" ? <div className="liked-card__featured-orange" key={index}> 
                <Star style={{width:"20px", marginRight:"0.2rem"}}/> {featureTag}
                 </div> : <div className="liked-card__featured" key={index}> 
                <Star style={{width:"20px", marginRight:"0.2rem"}}/> {featureTag}
                 </div>;
              })}
            </div>
          </div>
          );
        })}
      </div>

      {zeroMatchesCase ? null :
      <div className="button-container" onClick={expandFunctionality}> 
        <div class="line"></div>
        <button class="view-button">
          {showAllCars ? "View less matches" : "View more matches"}
          {showAllCars ? <ChevronUp /> :  <ChevronDown />}
        </button>
          <div class="line"></div>
      </div>}

    </div>}

    {/* remove ! for display */ !maeCarMatches.length > 0 ? <div className="gallery__hero" id="no-print-header-two">
        {zeroMatchesCase? null :
        <div className="matches_page_heading">
          <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
          <h1 className ="matches_page_heading--text">Ford Model Matches</h1>
          </div>
        </div>}

        {zeroMatchesCase? null :
        <p> Matched to the recommended trim level</p> }
    </div>: null}

    {/* remove ! for display */ !maeCarMatches.length ?
    <div className="liked-list" id="no-print-list-two">
    <div className={`liked-list__wrapper`}>
      {maeCarMatches.map((vehicle,index) => {
        return (
          <Link 
          to={linkMatchesToVDP ? vehicle.internalLink: null} 
          key={`mae-matches-${index}`} target={linkMatchesToVDP ? "_blank": "_self"}
          onClick={(e) => {
            if (!linkMatchesToVDP) {
              e.preventDefault();
            }
          }}>
          <div className={`liked-card`}>
              <div className="liked-card__thumbnail">
                <img
                  className="liked-card__thumbnail--img"
                  src={defaultInventoryImage? defaultInventoryImage:vehicle.imageLink}
                  alt={vehicle.make + vehicle.model}
                />
              </div>
            <div className="liked-card__details">
              {/* <div className={`liked-card__details--${vehicle.newOrUsed}`}>
                {`${vehicle.newOrUsed}`}
              </div> */}
              <div className="liked-card__details--title">
                {`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`}
              </div>
              <div className="liked-card__details--price">
                {/* <div style={{display:"flex",alignItems:"center"}}>
                  ${formatPrice(vehicle.baseMSRP)}
                  {vehicle.discountedFlag?<img className="liked-card__details--discount" alt="" src={discountedIcon}></img>:null}
                </div>{" "} */}
              </div>
            </div>
            <MatchSection vehicle={vehicle} />
          </div>
        </Link>
        );
      })}
    </div>
    <ComparisonChartStatic targetDivRef={targetDivRef} carsToCompare={carMatches} name={user.firstName} lifestyle={user.profile.quiz[LIFESTYLE_INDEX].selectedOptions} carsToCompareIndexes={carsToCompareList /* finalCompareList */ } matchesPage={true}/>
  </div>: 
    <div className="liked-list" id="no-print-list-two">
    <ComparisonChartStatic targetDivRef={targetDivRef} carsToCompare={carMatches} name={user.firstName} lifestyle={user.profile.quiz[LIFESTYLE_INDEX].selectedOptions} carsToCompareIndexes={carsToCompareList /* finalCompareList */ } matchesPage={true}/>
    </div>
  }
   
    {/* Hidden Print-Only UserProfile component */}
    <div id="print">
      <div style={{display:"flex",justifyContent:"space-between",paddingBottom:"1rem"}}>
      <button style={{background:"None", border:"None",  fontSize:"1rem"}} onClick={backToMatches}>  &#x25c0; Back</button>
      <button style={{background:"None", border:"None",fontSize:"1rem"}} onClick={()=>{window.print()}}>  Print</button>
      </div>
      <UserProfile user={user} dealershipImgUrl={dealershipImgUrl} carMatches={carMatches}/>
    </div>

    <div id="second-print">
        <div style={{display:"flex",justifyContent:"space-between",paddingBottom:"1rem"}}>
        <button style={{background:"None", border:"None",  fontSize:"1rem"}} onClick={backToMatches}>  &#x25c0; Back</button>
        <button style={{background:"None", border:"None",fontSize:"1rem"}} onClick={()=>{window.print()}}>  Print</button>
        </div>
        <ComparisonChartStatic carsToCompare={carMatches} name={user.firstName} lifestyle={user.profile.quiz[LIFESTYLE_INDEX].selectedOptions} carsToCompareIndexes={carsToCompareList /* finalCompareList */ } matchesPage={false}/>
    </div>
  </div>
  
  );
}

export default LikedList;
