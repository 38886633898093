export const DEALERSHIP_CONFIG = {
    "camclarkford":{
        name: "Cam Clark Ford",
        imgUrl: "https://ddztmb1ahc6o7.cloudfront.net/camclarkfordrichmond/wp-content/uploads/2024/04/12160321/Cam-Clark-Ford-PNG.png",
        linkMatchesToVDP: true,
        defaultInventoryImage: false,
        salesPeople: {
            0:{name:"",email:""},
            1:{name:"Dennis Liu", email:"hliu@camclarkford.com"},
            2:{name:"Chris Yu", email:"cyu@camclarkford.com"},
            3:{name:"Daniel Chiu" ,email:"dchiu@camclarkford.com"},
            4:{name:"Lawrence De Leon" ,email:"ldeleon@camclarkford.com"},
            5:{name:"Jeffrey Ng" ,email:"jng@camclarkford.com"},
            6:{name:"Dev Bhardwaj" ,email:"dbhardwaj@camclarkford.com"},
            7:{name:"Nicole Bullock" ,email:"nbullock@camclarkford.com"},
            8:{name:"Emre Celik" ,email:"ecelik@camclarkford.com"}
        },
        // salesPeople: {
        //     0:["",""],
        //     1:["sp1 Dennis Liu", "sp1@makingautoeasy.com"],
        //     2:["sp2 Chris Yu", "sp2@makingautoeasy.com"],
        //     3:["sp3 Daniel Chiu" ,"sp3@makingautoeasy.com"],
        //     4:["sp4 Lawrence De Leon" ,"sp4@makingautoeasy.com"],
        //     5:["sp5 Jeffrey Ng" ,"sp5@makingautoeasy.com"],
        //     6:["sp6 Dev Bhardwaj" ,"sp6@makingautoeasy.com"],
        //     7:["sp7 Nicole Bullock" ,"sp7@makingautoeasy.com"],
        //     8:["sp8 Emre Celik" ,"sp8@makingautoeasy.com"]
        // }
    },
    "trial":{
        name: "Your Dealership Name",
        imgUrl: "https://mae-trim-images.s3.us-east-2.amazonaws.com/Black+and+White+Illustrative+Auto+Dealership+Logo+Design+(2)+(1).png",
        linkMatchesToVDP: false,
        defaultInventoryImage: "https://mae-trim-images.s3.us-east-2.amazonaws.com/Black+and+White+Illustrative+Auto+Dealership+Logo+Design+(3).png"
    }
}