export const questions = [
  {
    text: "Let's start - where are you in your car buying journey?",
    subtext : "",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "I know what I want",
        subtext: "",
        greyicon: "ik.svg",
        selected: false,
      },
      {
        text: "I don’t know just yet",
        subtext: "",
        greyicon: "idk.svg",
        selected: false,
      },
    ],
  },
  {
    text: "Vehicles",
    subtext : "",
    selectionRange: [1, 1],
    currSelected: [],
    vehicleInfo : ""
  },
  {
    text: "Why are you looking for a new car?",
    subtext: "",
    currSelected: [],
    selectionRange: [1, 1],
    visitedOnce: false,
    options: [
      {
        text: "My current car is aging.",
        subtext: "",
        greyicon: "quiz-clock-grey.svg",
        selected: false,
      },
      {
        text: "I need more space for my stuff.",
        subtext: "",
        greyicon: "quiz-upsize-grey.svg",
        selected: false,
      },
      {
        text: "New member in the family.",
        subtext: "",
        greyicon: "quiz-baby-grey.svg",
        selected: false,
      },
      {
        text: "Getting my first car!",
        subtext: "",
        greyicon: "quiz-firstcar-grey.svg",
        selected: false,
      },
      {
        text: "Treating Myself!",
        subtext: "",
        greyicon: "quiz-gift-grey.svg",
        selected: false,
      },
      {
        text: "Downsizing",
        subtext: "",
        greyicon: "quiz-downsize-grey.svg",
        selected: false,
      },
      {
        text: "Other",
        subtext: "",
        greyicon: "",
        selected: false,
      },
    ],
  },
  {
    text: "What type of vehicle are you looking for?",
    subtext: "Select one",
    currSelected: [],
    selectionRange: [1, 1],
    visitedOnce: false,
    options: [
      {
        text: "Car",
        subtext: "A sleek looking car, perfect for city life and weekend escapes",
        greyicon: "quiz-car-grey.svg",
        selected: false,
      },
      {
        text: "Small SUV",
        subtext: "A 5-seater with just enough trunk space for your everyday needs",
        greyicon: "quiz-suv-grey.svg",
        selected: false,
      },
      {
        text: "Medium SUV",
        subtext: "A slightly larger 5-seater with more trunk space",
        greyicon: "quiz-suv-grey.svg",
        selected: false,
      },
      {
        text: "Large SUV",
        subtext: "The roomiest 2 row or 3 row SUVs for larger families",
        greyicon: "quiz-suv-grey.svg",
        selected: false,
      },
      {
        text: "Minivan",
        subtext: "A spacious 7-seater, ideal for family adventures and daily errands",
        greyicon: "quiz-minivan-grey.svg",
        selected: false,
      },
      {
        text: "Truck",
        subtext: "A robust 5-seater, ideal for outdoor excursions and heavy loads.",
        greyicon: "quiz-truck.svg",
        selected: false,
      },
    ],
  },
  { 
    text: "How do you want your vehicle powered?",
    subtext: "Select your most preferred power type",
    currSelected: [],
    selectionRange: [1, 2],
    visitedOnce: false,
    options: [
      {
        text: "Gasoline",
        subtext: "",
        greyicon: "quiz-gas-grey.svg",
        selected: false,
      },
      {
        text: "Hybrid",
        subtext: "",
        greyicon: "quiz-hybrid-grey.svg",
        selected: false,
      },
      {
        text: "Plug-in Hybrid",
        subtext: "",
        greyicon: "quiz-plugin-grey.svg",
        selected: false,
      },
      {
        text: "Electric",
        subtext: "",
        greyicon: "quiz-ev-grey.svg",
        selected: false,
      },
      {
        text: "Diesel",
        subtext: "",
        greyicon: "quiz-diesel-grey.svg",
        selected: false,
      },
    ],
  },
  { 
    text: "Have you owned an EV before?",
    subtext: "",
    currSelected: [],
    selectionRange: [1, 1],
    visitedOnce: false,
    options: [
      {
        text: "Yes",
        subtext: "",
        greyicon: "",
        selected: false,
      },
      {
        text: "No",
        subtext: "",
        greyicon: "",
        selected: false,
      }
    ],
  },
  { 
    text: "Have you ever tried the One-Pedal or Roll Assist (Tesla) driving feature in an Electric Vehicle?",
    subtext: "",
    currSelected: [],
    selectionRange: [1, 1],
    visitedOnce: false,
    options: [
      {
        text: "Yes",
        subtext: "",
        greyicon: "",
        selected: false,
      },
      {
        text: "No",
        subtext: "",
        greyicon: "",
        selected: false,
      }
    ],
  },
  {
    
    text: "What's your lifestyle?",
    subtext: "Let us find a car that best matches your day to day needs and aligns with what’s important to you.",
    currSelected: [],
    selectionRange: [1, 1],
    subOptionsTracker : [],
    visitedOnce: false,
    options: [
      {
        text: "Colours",
        subtext: "",
        greyicon: "",
        selected: false,
        currSelected: [],
        selectionRange: [1, 8],
        sub_options : [
          {
            text : "⚫ Black",
            selected : false,
            icon : ""
          },
          {
            text : "⚪ White",
            selected : false,
            icon : ""
          },
          {
            text : "🩶 Grey",
            selected : false,
            icon : ""
          },
          {
            text : "🔵 Blue",
            selected : false,
            icon : ""
          },
          {
            text : "🔴 Red",
            selected : false,
            icon : ""
          },
          {
            text : "🟢 Green",
            selected : false,
            icon : ""
          },
          {
            text : "🟡 Yellow",
            selected : false,
            icon : ""
          },
          {
            text : "Other Colour",
            selected : false,
            icon : ""
          },
        ]
      },
      {
        text: "Family",
        subtext: "",
        greyicon: "Quiz-Errands.png",
        selected: false,
        currSelected: [],
        selectionRange: [1, 8],
        sub_options : [
          {
            text : "👨🏻 Tall Family Members",
            selected : false,
            icon : ""
          },
          {
            text : "🐶 Large Dog",
            selected : false,
            icon : ""
          },
          {
            text : "🛞 Multiple Main Drivers",
            selected : false,
            icon : ""
          },
          {
            text : "👶🏻 1-2 Kids",
            selected : false,
            icon : ""
          },
          {
            text : "👶🏻 3-4 Kids",
            selected : false,
            icon : ""
          },
          {
            text : "👶🏻 5+ Kids",
            selected : false,
            icon : ""
          },
          {
            text : "👵🏻 Older Family Members",
            selected : false,
            icon : ""
          },
          {
            text : "🆕 New Driver",
            selected : false,
            icon : ""
          },
        ]
      },
      {
        text: "Outdoors and Equipment",
        subtext: "",
        greyicon: "Quiz-Work.png",
        selected: false,
        currSelected: [],
        selectionRange: [1, 9],
        sub_options : [
          {
            text : "💺 Car Seat(s)",
            selected : false,
            icon : ""
          },
          {
            text : "⛳ Golfing",
            selected : false,
            icon : ""
          },
          {
            text : "⛷️ Skiing/Snowboarding",
            selected : false,
            icon : ""
          },
          {
            text : "🚴🏻 Biking",
            selected : false,
            icon : ""
          },
          {
            text : "😴 Car Sleeping",
            selected : false,
            icon : ""
          },
          {
            text : "🏕️ Camping",
            selected : false,
            icon : ""
          },
          {
            text : "🛶 Canoe/Kayak",
            selected : false,
            icon : ""
          },
          {
            text : "⚽ Sports Practices",
            selected : false,
            icon : ""
          },
          {
            text : "🏊 Water Sports",
            selected : false,
            icon : ""
          },
        ]
      },
      {
        text: "Towing",
        subtext: "",
        greyicon: "Quiz-Shuttle-Bus.png",
        selected: false,
        currSelected: [],
        selectionRange: [1, 3],
        sub_options : [
          {
            text : "Trailer",
            selected : false,
            icon : "van.png"
          },
          {
            text : "🛥️ Boat",
            selected : false,
            icon : ""
          },
          {
            text : "🏕️ Camper",
            selected : false,
            icon : ""
          }
        ]
      },
      {
        text: "Driving Geography",
        subtext: "",
        greyicon: "Quiz-Outdoors.png",
        selected: false,
        currSelected: [],
        selectionRange: [1, 6],
        sub_options : [
          {
            text : "Off Roading",
            selected : false,
            icon : "jeep.png"
          },
          {
            text : "❄️ Snow",
            selected : false,
            icon : ""
          },
          {
            text : "⛰️ Steep/Hilly Roads",
            selected : false,
            icon : ""
          },
          {
            text : "🏎️ Sporty Driving",
            selected : false,
            icon : ""
          },
          {
            text : "🏙️ Lots of City/Traffic",
            selected : false,
            icon : ""
          },
          {
            text : "🛣️ Lots of Highway Driving",
            selected : false,
            icon : ""
          },
        ]
      }
    ],
  },
  {
    
    text: "What are some of your must-have features?",
    subtext:
      "Select any that are important to you",
    currSelected: [],
    selectionRange: [1, 8],
    visitedOnce: false,
    options: [
      {
        text: "Heated Seats",
        subtext: "",
        greyicon: "feature-heated-seats-grey.svg",
        selected: false,
        field: "heatedSeats",
      },
      {
        text: "Heated Steering Wheel",
        subtext: "",
        greyicon: "feature-heated-steering-wheel-grey.svg",
        selected: false,
        field: "heatedSteeringWheel",
      },
      {
        text: "Keyless Entry",
        subtext: "",
        greyicon: "feature-push-start-grey.svg",
        selected: false,
        field: "keylessEntry",
      },
      {
        text: "Seat Memory",
        subtext: "",
        greyicon: "feature-seat-memory-grey.svg",
        selected: false,
        field: "seatMemory",
      },
      {
        text: "Power Seats",
        subtext: "",
        greyicon: "feature-power-seats-grey.svg",
        selected: false,
        field: "powerSeats",
      },
      {
        text: "Apple CarPlay/Android Auto",
        subtext: "",
        greyicon: "feature-carplay-grey.svg",
        selected: false,
        field: "appleCarPlayAndroidAuto",
      },
      {
        text: "Backup Camera",
        subtext: "",
        greyicon: "feature-sunroof-grey.svg",
        selected: false,
        field: "backupCamera",
      },
      {
        text: "Power Liftgate",
        subtext: "",
        greyicon: "feature-power-liftgate-grey.svg",
        selected: false,
        field: "powerLiftgate",
      },
    ],
  },
  {
    
    text: "What is most important to you?",
    subtext: "Please select the number next to each item to indicate your most important (1) to least important (5) element of your new car",
    currSelected: ["Fuel Economy","Price","Safety","Tech-y Features", "Convenience Features"],
    selectionRange: [1,1],
    visitedOnce: false,
    rankingOptions : ["Fuel Economy","Price","Safety","Tech-y Features","Convenience Features"],
    ranking : {"Fuel Economy" : 1, "Price" : 2, "Safety" : 3, "Tech-y Features" : 4, "Convenience Features" : 5},
  },
  {
    
    text: "What’s your ideal budget?",
    type: "budget",
    currSelected: [],
    selectionRange: [1, 1],
    visitedOnce: false,
    minBudget: "",
    maxBudget: "",
    monthlyBudget: "",
  },
  {
    
    text: "Are you buying New or Used?",
    subtext: "Or Both!",
    currSelected: [],
    selectionRange: [1, 2],
    visitedOnce: false,
    options: [
      {
        text: "New",
        subtext: "New is the only way to go for me! I’d like to be the first one to own or lease this vehicle.",
        greyicon: "quiz-new.svg",
        selected: false,
      },
      {
        text: "Used",
        subtext: "I don’t need a brand-new car! I’m looking for a used or certified pre-owned vehicle to bring into the family.",
        greyicon: "quiz-old.svg",
        selected: false,
      },
    ],
  },
  {
    text: "Any cars you are currently considering?",
    subtext : "This helps us understand what you like. We're here to help you discover your options in an unbiased way.",
    selectionRange: [1, 5],
    visitedOnce: false,
    currSelected: [{make : "", model : ""}],
    noOfCarsAdded : 0,
  },
  {
    
    text: "What other cars are you exploring?",
    subtext : "Mae’s concierge can build you an unbiased comparison chart to make clear and fast decisions.",
    selectionRange: [1, 5],
    visitedOnce: false,
    currSelected: [{make : "", model : ""}],
    noOfCarsAdded : 0,
  },
  {
    
    text: "Would you like to Trade in your car?",
    subtext: "",
    selectionRange: [1, 1],
    visitedOnce: false,
    currSelected: [],
    options: [
      {
        text: "Yes",
        subtext: "",
        greyicon: "Quiz-Correct.svg",
        selected: false,
      },
      {
        text: "No",
        subtext: "",
        greyicon: "Quiz-Wrong.svg",
        selected: false,
      },
      {
        text: "Haven’t decided",
        subtext: "",
        greyicon: "Quiz-Unsure.svg",
        selected: false,
      },
    ],
  },
  {
    
    text: "What Vehicle are you trading in?",
    subtext: "",
    selectionRange: [1, 1],
    visitedOnce: false,
    currSelected: [],
    tradeInInfo : ""
  },
  {
    
    text: "Are you interested in buying Cash or Financing or Leasing?",
    subtext: "",
    selectionRange: [1, 1],
    visitedOnce: false,
    currSelected: [],
    options: [
      {
        text: "Cash",
        subtext: "I’d like to own my car and pay for it in full with no monthly payments or interest",
        greyicon: "Quiz-Cash.svg",
        selected: false,
      },
      {
        text: "Finance",
        subtext: "I’d like to own my car and spread out the cost in monthly payments.",
        greyicon: "Quiz-Finance.svg",
        selected: false,
      },
      {
        text: "Lease",
        subtext: "I’d like lower monthly payments without the long-term commitment of ownership.",
        greyicon: "Quiz-Lease.svg",
        selected: false,
      },
    ],
  },
  {
    
    text: "To complete your personalized check-in, please provide your contact details.",
    subtext: "",
    selectionRange: [5, 5],
    visitedOnce: false,
    currSelected: [],
    firstName: "",
    lastName: "",
    phoneNo: "",
    email: "Not Mentioned",
    preferredMethodOfContact: "email",
    salesPersonInfo : ""
  },
];
