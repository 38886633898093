import { produce, current } from "immer";
import QuizOptionCard from "../QuizOptionCard/QuizOptionCard";
import './LifestyleComponent.scss';
import { LIFESTYLE_INDEX } from "../../constants/quizQuestionsIndex";
import React from "react";

function QuizOptionCardContainer({
  index,  
  quizQuestions,
  setQuizQuestions,
  currentQuestionIndex,
}) {
  const selectOption = (optionIndex) => {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const lifestyleQuestionObj = quizQuestionsDraft[currentQuestionIndex];
        const selectedOptions = quizQuestionsDraft[currentQuestionIndex].options[index].currSelected;
        const maxOptionLen = quizQuestionsDraft[currentQuestionIndex].options[index].selectionRange[1];
        const selectedOptionsIndex = selectedOptions.indexOf(optionIndex);
        if (selectedOptions.length >= maxOptionLen) {
          const removedOptionInfo = selectedOptions.shift();
          quizQuestionsDraft[currentQuestionIndex].options[index].sub_options[
            removedOptionInfo
          ].selected = false;
        }
        if (
          selectedOptionsIndex === -1 && selectedOptions.length < maxOptionLen
        ) {
          selectedOptions.push(optionIndex);
          quizQuestionsDraft[currentQuestionIndex].options[index].sub_options[optionIndex].selected = true;
          let subOption = lifestyleQuestionObj.options[index].sub_options[optionIndex].text;

          for(let i = 0; i<subOption.length; i++) {
            const code = subOption.charCodeAt(i);
            if((code >= 65 && code <= 90) || (code >= 97 && code <= 122) || (code >= 48 && code <= 57)) {
              subOption = subOption.substr(i);
              break;
            }
          }

          quizQuestionsDraft[currentQuestionIndex].currSelected.push(subOption);
          lifestyleQuestionObj.subOptionsTracker.push(`${index}${optionIndex}`);
        }
        return quizQuestionsDraft;
      })
    );
  };

  const unselectOption = (optionIndex) => {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const selectedOptions = quizQuestionsDraft[currentQuestionIndex].options[index].currSelected;
        const selectedOptionsIndex = selectedOptions.indexOf(optionIndex);
        if (selectedOptionsIndex === -1) {
          return;
        }
        quizQuestionsDraft[currentQuestionIndex].options[index].sub_options[optionIndex].selected = false;
        quizQuestionsDraft[currentQuestionIndex].options[index].currSelected.splice(selectedOptionsIndex,1);
        const subOptionsTrackerIndex = quizQuestionsDraft[currentQuestionIndex].subOptionsTracker.indexOf(`${index}${optionIndex}`);
        if(subOptionsTrackerIndex === -1) {
          return;
        }
        quizQuestionsDraft[currentQuestionIndex].subOptionsTracker.splice(subOptionsTrackerIndex,1);
        quizQuestionsDraft[currentQuestionIndex].currSelected.splice(subOptionsTrackerIndex,1);
      })
    );
  };

  // console.log(quizQuestions[currentQuestionIndex].currSelected)
  // console.log(quizQuestions[currentQuestionIndex].subOptionsTracker)

  return (
      <>
      {quizQuestions[currentQuestionIndex].options[index].sub_options.map((option, i) => (
        <QuizOptionCard
          key = {index * 100 + i}
          index={i}
          text={option.text}
          subtext={""}
          greyicon={option.icon}
          selectOption={selectOption}
          unselectOption={unselectOption}
          selected={option.selected}
          className={"feature-card"}
        />
      ))}
    </>
  );
}
export default function LifeStyleComponent({ 
    quizQuestions,
    setQuizQuestions,
    currentQuestionIndex
}) {
  return (
    <div className = "lifestyle-question-container">
      {quizQuestions[LIFESTYLE_INDEX].options.map((mainOption, index) => {
        return (
        <div key = {index}>
        <div className = "lifestyle-question-subcontainer">
        <p className = "lifestyle-option-title">{mainOption.text}</p>
        <div key={index} className="four_cards quiz__options_cards">
          <QuizOptionCardContainer
            key = {index}
            index={index}
            quizQuestions={quizQuestions}
            setQuizQuestions={setQuizQuestions}
            currentQuestionIndex={currentQuestionIndex}
          />
        </div>
        </div>
        </div>
      )}
      )}
    </div>
  );
}

