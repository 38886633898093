import { BUDGET_INDEX, FEATURE_SELECT_INDEX, IK_VEHICLE_INPUT_INDEX, LIFESTYLE_INDEX, NEW_USED_INDEX, PICK_NEW_CARS_INDEX, PICK_USED_CARS_INDEX, TRADE_IN_INDEX, TRADE_IN_INFO_INDEX, VALUE_RANKING_INDEX, VEHICLE_ENGINE_INDEX, VEHICLE_TYPE_INDEX } from "../../constants/quizQuestionsIndex"
import {ReactComponent as MaeCoreLogoBlack} from '../../assets/logos/MaeCoreLogoBlack.svg'
import { MONTHLY_BUDGET_VALUES } from "../../data/budgetData";

export default function UserProfile ({ user, dealershipImgUrl, carMatches }) {

  return (
  <div style={{ maxWidth: '600px', display:"flex",flexDirection:"column",gap:"0.5rem" }} className="printable">
    <img src={dealershipImgUrl} alt = "" style={{width:"180px"}}/>
    <div className="product_info__creator_section" style={{alignItems:"center", paddingTop:"0.5rem"}}>
      <h2 style={{margin:"0.5rem 0rem"}}>Customer Profile</h2>
          <div className="product_info__text" style={{fontWeight : "400",width:"auto"}}>Powered By</div>
          <div className="product_info__logo">
             <MaeCoreLogoBlack />
          </div>
    </div>
    <p><strong>Summary:</strong> {user.firstName} {user.lastName} (Phone Number: {user.phoneNo}) 
    is looking for a {user.profile.quiz[NEW_USED_INDEX].selectedOptions.join(', ')} {" "}
    {user.profile.quiz[VEHICLE_TYPE_INDEX].selectedOptions.join(', ')} {" "}
    in the range of {user.profile.quiz[BUDGET_INDEX].selectedOptions[0]} {" "}
    with a {Array.from(new Set(user.profile.quiz[VEHICLE_ENGINE_INDEX].selectedOptions)).join(', ')} engine. 
    Lifestyle is {user.profile.quiz[LIFESTYLE_INDEX].selectedOptions.join(', ')}. {" "}
    {user.profile.quiz[IK_VEHICLE_INPUT_INDEX].selectedOptions.length > 0? `They came to the dealership to see ${user.profile.quiz[IK_VEHICLE_INPUT_INDEX].selectedOptions.join(', ')}.`: ""}</p>
    <p>First Name: <strong>{user.firstName}</strong></p>
    <p>Last Name: <strong>{user.lastName}</strong></p>
    <p>Email: <strong>{user.email}</strong></p>
    <p>Phone Number: <strong>{user.phoneNo}</strong></p>
    <p>Preferred Method of Contact: <strong>{user.preferredMethodOfContact}</strong></p>
   {user.profile.quiz.map((item, index) => {

    if(index == TRADE_IN_INFO_INDEX){
      console.log(item)
    }

      if(index === VALUE_RANKING_INDEX) {
        return null
      }
      let { question, selectedOptions } = item;

      if(index === VEHICLE_ENGINE_INDEX){
        selectedOptions = Array.from(new Set(selectedOptions));
      }
    
      if(index === FEATURE_SELECT_INDEX){
        selectedOptions = user.userSelectedFeatures
      } 
      let answers = selectedOptions.length > 0 ? selectedOptions.join(', ') : 'No answers selected';

      if((index === PICK_NEW_CARS_INDEX || index === PICK_USED_CARS_INDEX) & answers !== 'No answers selected') {
        answers = selectedOptions.map(({make,model}) => {return `${make} ${model}`}).join(', ')
      }

      if(index == BUDGET_INDEX && selectedOptions[1] == 'monthly') {
        const monthlyPayment = MONTHLY_BUDGET_VALUES.find(item => item.value === selectedOptions[0]);
        answers += " (" + monthlyPayment.text + ")"
      }

      return answers === 'No answers selected'?null
      :<div key={`print-${index}`}><div>
          <p>
          {question} <strong>{answers}</strong>
          </p>
        </div>
        </div>;
      
})}
  <p>Number of Matches: <strong>{carMatches.length}</strong></p>
  
  {
    carMatches.length === 0 ? null : <p>Matched New Vehicles: <strong>
      {carMatches
      .filter((vehicle)=> (vehicle.newOrUsed === "New"))
      .map((vehicle) => (`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim} (${vehicle.stockNumber})`))
      .join(", ")}</strong></p>
  }

  {
    carMatches.length === 0 ? null : <p>Matched Used Vehicles: <strong>
      {carMatches
      .filter((vehicle)=> (vehicle.newOrUsed === "Used"))
      .map((vehicle) => (`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim} (${vehicle.stockNumber})`))
      .join(", ")}</strong></p>
  } 
  </div> )
};
